import React from 'react'

// Images 
import aboutImg from '../../assets/images/about/a2.jpg'
import aboutShape1 from '../../assets/images/shape/shape4.png'
import aboutShape2 from '../../assets/images/shape/shape5.png'
import aboutProfile from '../../assets/images/testimonial/tc1.jpg'

const AboutTwo = () => {
    return (
        <section className="about ptb-100 ">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-img-2">
                            <img 
                                className="main-img" 
                                src={aboutImg} 
                                alt="About Shape"
                            />
                            <div className="shape" >
                                <div className="shape1" data-speed="0.2" data-revert="true">
                                    <img 
                                        src={aboutShape1} 
                                        alt="About Shape"
                                    />
                                </div>
                                <div className="shape2" data-speed="0.1" data-revert="true">
                                    <img 
                                        src={aboutShape2} 
                                        alt="About Shape"
                                    />
                                </div>
                            </div>
                            <div className="about-exp">
                                <h4>45+</h4>
                                <p>Years Of Experience</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="about-text-area-2 pl-20">
                            <div className="default-section-title">
                                <span>About Our Company</span>   
                                <h3>Get To Audience With Targeted Ads On Associative Media</h3>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, do eiusmod tempo Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, do eiusmod tempo incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrice.Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                            <div className="about-list">
                                <ul>
                                    <li><i className="fas fa-check"></i> We do Give Our Best</li>
                                    <li><i className="fas fa-check"></i> Traffic Analysis</li>
                                    <li><i className="fas fa-check"></i> Highly Professionalism</li>
                                    <li><i className="fas fa-check"></i> Robust Web Plan</li>
                                    <li><i className="fas fa-check"></i> Cross-border Operability</li>
                                    <li><i className="fas fa-check"></i> Dedicated Team</li>
                                </ul>
                            </div>
                            <div className="about-intro">
                                <img 
                                    src={aboutProfile} 
                                    alt="About Profile"
                                />
                                <div className="at-text">
                                    <h4>Adam Rueter</h4>
                                    <p>Founder Manager</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutTwo
