import React from 'react'

// Images 
import testimonialImg from '../../assets/images/testimonial/tc2.jpg'
import testimonialImg2 from '../../assets/images/testimonial/tc3.jpg'
import testimonialImg3 from '../../assets/images/testimonial/tc4.jpg'
import testimonialImg4 from '../../assets/images/testimonial/tc5.jpg'
import testimonialImg5 from '../../assets/images/testimonial/tc6.jpg'

import Loadable from '@loadable/component';
const OwlCarousel = Loadable(() => import('react-owl-carousel3'));

const options = {
    loop: true,
    margin: 10,
    nav: true,
    responsiveClass: true,
    navText: [
        '<i class="fas fa-angle-left"></i>', 
        '<i class="fas fa-angle-right"></i>'
    ],
    dots: true,
    autoplay: true,
    autoplayHoverPause: true,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        992: {
            items: 2,
        },
        1200: {
            items: 3,
        },
    }
};

const TestimonialsTwo = () => {
    return (
        <section className="testimonial ptb-100">
            <div className="container">
                <div className="default-section-title default-section-title-middle">
                    <span>Testimonials</span>   
                    <h3>Our Happy Clients</h3>
                </div>

                <div className="section-content">
                    <OwlCarousel 
                        className="testimonial-slider-area-2 owl-carousel slider-area-nav"
                        {...options}
                        >
                        <div className="testimonial-card-2">
                            <div className="testimonial-card-text-2">
                                <i className="flaticon-right-quotes-symbol"></i>
                                <p>“Had a fantastic time at Glit, got to meet a lot of great people and hear some amazing talks. Thanks @glit for a super day!”</p>
                            </div>
                            <div className="testimonial-card-2-intro">
                                <img 
                                    src={testimonialImg} 
                                    alt="testimonial"
                                />
                                <h4>Robert Cook</h4>
                                <p>Front End Developer</p>
                            </div>
                        </div>

                        <div className="testimonial-card-2">
                            <div className="testimonial-card-text-2">
                                <i className="flaticon-right-quotes-symbol"></i>
                                <p>“Had a fantastic time at Glit, got to meet a lot of great people and hear some amazing talks. Thanks @glit for a super day!”</p>
                            </div>
                            <div className="testimonial-card-2-intro">
                                <img 
                                    src={testimonialImg2} 
                                    alt="testimonial"
                                />
                                <h4>Paul White</h4>
                                <p>Back End Developer</p>
                            </div>
                        </div>

                        <div className="testimonial-card-2">
                            <div className="testimonial-card-text-2">
                                <i className="flaticon-right-quotes-symbol"></i>
                                <p>“Had a fantastic time at Glit, got to meet a lot of great people and hear some amazing talks. Thanks @glit for a super day!”</p>
                            </div>
                            <div className="testimonial-card-2-intro">
                                <img 
                                    src={testimonialImg3} 
                                    alt="testimonial"
                                />
                                <h4>Jerry Monero</h4>
                                <p>Front End Developer</p>
                            </div>
                        </div>

                        <div className="testimonial-card-2">
                            <div className="testimonial-card-text-2">
                                <i className="flaticon-right-quotes-symbol"></i>
                                <p>“Had a fantastic time at Glit, got to meet a lot of great people and hear some amazing talks. Thanks @glit for a super day!”</p>
                            </div>
                            <div className="testimonial-card-2-intro">
                                <img 
                                    src={testimonialImg4} 
                                    alt="testimonial"
                                />
                                <h4>David Smith</h4>
                                <p>Manager</p>
                            </div>
                        </div>

                        <div className="testimonial-card-2">
                            <div className="testimonial-card-text-2">
                                <i className="flaticon-right-quotes-symbol"></i>
                                <p>“Had a fantastic time at Glit, got to meet a lot of great people and hear some amazing talks. Thanks @glit for a super day!”</p>
                            </div>
                            <div className="testimonial-card-2-intro">
                                <img 
                                    src={testimonialImg5} 
                                    alt="testimonial"
                                />
                                <h4>Jack Parker</h4>
                                <p>Manager</p>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </div>
        </section>
    )
}

export default TestimonialsTwo
