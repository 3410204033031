import React from 'react'
import { Link } from 'gatsby'

// Images 
import serviceImg from '../../assets/images/service/s5.jpg'
import serviceImg2 from '../../assets/images/service/s6.jpg'
import serviceImg3 from '../../assets/images/service/s7.jpg'
import serviceImg4 from '../../assets/images/service/s8.jpg'

import Loadable from '@loadable/component';
const OwlCarousel = Loadable(() => import('react-owl-carousel3'));

const options = {
    loop: true,
    responsiveClass: true,
    nav: true,
    navText: [
        '<i class="fas fa-angle-left"></i>', 
        '<i class="fas fa-angle-right"></i>'
    ],
    dots: false,
    autoplay: true,
    autoplayHoverPause: true,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        1200: {
            items: 3,
        },
    }
};

const ServiceSlider = () => {
    return (
        <div className="services ptb-100">
            <div className="container">
                <div className="default-section-title default-section-title-middle">
                    <span>Our Services</span>   
                    <h3>Services For Business</h3>
                </div>

                <div className="section-content">
                    <OwlCarousel 
                        className="service-slider-area owl-carousel slider-area-nav"
                        {...options}
                        >
                        <div className="service-card-2">
                            <Link to="/service-details">
                                <img 
                                    src={serviceImg } 
                                    alt="service" 
                                />
                            </Link>
                            <div className="service-card-2-text">
                                <h4><Link to="/service-details">Simple Business Answers And Solutions</Link></h4>
                                <p>Dolore magna aliqua quis ipsum suspendisse ultrices gravida. Risus commodo maecenas accumsan lacus vel facilisis. </p>
                                <Link className="read-more-btn" to="/service-details">Explore Now <i className="fas fa-arrow-right"></i></Link>
                            </div>
                        </div>

                        <div className="service-card-2">
                            <Link to="/service-details">
                                <img src={serviceImg2} 
                                alt="service" 
                                />
                            </Link>
                            <div className="service-card-2-text">
                                <h4><Link to="/service-details">Competitive Research & Assurance</Link></h4>
                                <p>Dolore magna aliqua quis ipsum suspendisse ultrices gravida. Risus commodo maecenas accumsan lacus vel facilisis. </p>
                                <Link className="read-more-btn" to="/service-details">Explore Now <i className="fas fa-arrow-right"></i></Link>
                            </div>
                        </div>

                        <div className="service-card-2">
                            <Link to="/service-details">
                                <img 
                                    src={serviceImg3} 
                                    alt="service" 
                                />
                            </Link>
                            <div className="service-card-2-text">
                                <h4><Link to="/service-details">Biopharma And Sciences From Our Life</Link></h4>
                                <p>Dolore magna aliqua quis ipsum suspendisse ultrices gravida. Risus commodo maecenas accumsan lacus vel facilisis. </p>
                                <Link className="read-more-btn" to="/service-details">Explore Now <i className="fas fa-arrow-right"></i></Link>
                            </div>
                        </div>

                        <div className="service-card-2">
                            <Link to="/service-details">
                                <img 
                                    src={serviceImg4} 
                                    alt="service" 
                                />
                            </Link>
                            <div className="service-card-2-text">
                                <h4><Link to="/service-details">International Business Opportunities</Link></h4>
                                <p>Dolore magna aliqua quis ipsum suspendisse ultrices gravida. Risus commodo maecenas accumsan lacus vel facilisis. </p>
                                <Link className="read-more-btn" to="/service-details">Explore Now <i className="fas fa-arrow-right"></i></Link>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </div>
        </div>
    )
}

export default ServiceSlider
