import React from 'react'
import { Link } from 'gatsby'

// Images 
import homeImg from '../../assets/images/banner/banner-img-2.png'

const HomeBanner = () => {
    return (
        <section className="home-banner">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="banner-text-area banner-text-area-2">
                            <h6>We Are Digital Marketing Expert</h6>
                            <h1>Build And Grow Relation With Their Customers</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod temp gravida. Risus commodo viverra maecenas accumsan facilisis.</p>
                            <div className="banner-buttons">
                                <ul>
                                    <li><Link className="default-button" to="/contact">Started Now <i className="fas fa-arrow-right"></i></Link></li>
                                    <li><Link className="default-button banner-button" to="/services">Explore More <i className="fas fa-arrow-right"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="banner-img-2">
                            <img 
                                src={homeImg} 
                                alt="Home Banner" 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section> 
    )
}

export default HomeBanner

