import React from 'react'
import Layout from '../components/_App/Layout'
import Seo from '../components/_App/Seo'
import NavbarTwo from '../components/_App/NavbarTwo'
import HomeBanner from '../components/Index-2/HomeBanner'
import BannerFeatures from '../components/Index-2/BannerFeatures'
import ServiceSlider from '../components/Common/ServiceSlider'
import AboutTwo from '../components/Common/AboutTwo'
import Team from '../components/Common/Team'
import WhyOnlyTwo from '../components/Index-2/WhyOnlyTwo'
import TestimonialsTwo from '../components/Common/TestimonialsTwo'
import WhyChooseTwo from '../components/Common/WhyChooseTwo'
import BlogPost from "../components/Common/BlogPost"
import Partner from '../components/Common/Partner'
import Footer from '../components/_App/Footer'
import Copyright from '../components/_App/Copyright'

const IndexPageTwo = () => {
    return (
        <Layout>
            <Seo title="Home Two"/>

            <NavbarTwo />

            <HomeBanner />

            <BannerFeatures />

            <ServiceSlider />

            <div className="gradient-bg1">
                <AboutTwo />
            </div>

            <Team />

            <WhyOnlyTwo />

            <TestimonialsTwo />
            
            <div className="gradient-bg1">
                <WhyChooseTwo />
            </div>
            
            <BlogPost />

            <Partner />

            <Footer />

            <Copyright />
        </Layout>
    )
}

export default IndexPageTwo
