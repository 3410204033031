import React from 'react'
import { Link } from 'gatsby'

// Images 
import blogImg from '../../assets/images/blog/b1.jpg'
import blogImg2 from '../../assets/images/blog/b2.jpg'
import blogImg3 from '../../assets/images/blog/b3.jpg'

const BlogPost = () => {
    return (
        <section className="blog ptb-100">
            <div className="container">
                <div className="default-section-title default-section-title-middle">
                    <span>Recent Blog Post</span>   
                    <h3>Our Latest News & Articles</h3>
                </div>
                <div className="section-content">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="blog-card">
                                <div className="blog-img-area">
                                    <Link to="/blog-details">
                                        <img 
                                            src={blogImg} 
                                            alt="blog" 
                                        />
                                    </Link>
                                    <div className="blog-img-date">
                                        <span>19</span>
                                        <span>Aug</span>
                                    </div>
                                </div>
                                <div className="blog-text-area">
                                    <div className="blog-date">
                                        <ul>
                                            <li><i className="far fa-user-circle"></i> By <Link to="/blog">Admin</Link> </li>
                                            <li><i className="far fa-comments"></i> 2 Comments</li>
                                        </ul>
                                    </div>
                                    <h4><Link to="/blog-details">Digital Marketing: Staying Relevant To The Empowered Consumer</Link></h4>
                                    <Link className="default-button default-button-2"  to="/blog-details">Read More <i className="fas fa-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="blog-card">
                                <div className="blog-img-area">
                                    <Link to="/blog-details">
                                        <img 
                                            src={blogImg2} 
                                            alt="blog" 
                                        />
                                    </Link>
                                    <div className="blog-img-date">
                                        <span>18</span>
                                        <span>Aug</span>
                                    </div>
                                </div>
                                <div className="blog-text-area">
                                    <div className="blog-date">
                                        <ul>
                                            <li><i className="far fa-user-circle"></i> By <Link to="/blog">Admin</Link> </li>
                                            <li><i className="far fa-comments"></i> 2 Comments</li>
                                        </ul>
                                    </div>
                                    <h4><Link to="/blog-details">Grow Your Business With A Digital Marketing Strategy</Link></h4>
                                    <Link className="default-button default-button-2"  to="/blog-details">Read More <i className="fas fa-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="blog-card">
                                <div className="blog-img-area">
                                    <Link to="/blog-details">
                                        <img 
                                            src={blogImg3} 
                                            alt="blog" 
                                        />
                                    </Link>
                                    <div className="blog-img-date">
                                        <span>17</span>
                                        <span>Aug</span>
                                    </div>
                                </div>
                                <div className="blog-text-area">
                                    <div className="blog-date">
                                        <ul>
                                            <li><i className="far fa-user-circle"></i> By <Link to="/blog">Admin</Link> </li>
                                            <li><i className="far fa-comments"></i> 2 Comments</li>
                                        </ul>
                                    </div>
                                    <h4><Link to="/blog-details">5 Inexpensive Online Tools To Get Your Business Off The Ground</Link></h4>
                                    <Link className="default-button default-button-2" to="/blog-details">Read More <i className="fas fa-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogPost
