import React from 'react'

// Images 
import chooseImg from '../../assets/images/why-we/ww4.jpg'
import chooseShape1 from '../../assets/images/shape/shape2.png'
import chooseShape2 from '../../assets/images/shape/shape3.png'
import chooseShape3 from '../../assets/images/shape/shape4.png'

const WhyChooseTwo = () => {
    return (
        <section className="why-we ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="why-we-img-area-2">
                            <div className="shape-img">
                                <img 
                                    className="shape1" 
                                    src={chooseShape1} 
                                    alt="shape"
                                />
                                <img 
                                    className="shape2" 
                                    src={chooseShape2} 
                                    alt="shape"
                                />
                                <img 
                                    className="shape3" 
                                    src={chooseShape3} 
                                    alt="shape"
                                />
                            </div>
                            <img 
                                className="main-img" 
                                src={chooseImg} 
                                alt="choose"
                            />
                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="why-we-text-area pl-20">
                            <div className="default-section-title">
                                <span>Why Choose Us</span>   
                                <h3>We Offer Unique & Advanced Designs Centers</h3>
                            </div>
                            <div className="why-card-area">
                                <div className="row">
                                    <div className="col-lg-9 col-md-6 col-sm-12 col-12">
                                        <div className="why-we-card">
                                            <h4>Developing Strategy</h4>
                                            <p>It is advantageous when the dummy text.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-9 col-md-6 col-sm-12 col-12">
                                        <div className="why-we-card">
                                            <h4>Blazing Performance</h4>
                                            <p>It is advantageous when the dummy text.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-9 col-md-6 col-sm-12 col-12">
                                        <div className="why-we-card">
                                            <h4>Customer satisfaction</h4>
                                            <p>It is advantageous when the dummy text.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseTwo
